<template>
 
    <!-- Our Partners Product Page --> 
    <!-- <img v-bind:src=our_partners_product_page> -->
   <!-- end Our Partners Product Page  -->
<!-- <div style="display:none;">{{ posts }}</div> -->

<div class="bubble-wrap thebubble2">
  bubble
  <div class="bubbles"></div>
</div>



   </template>

   <script>
  import $ from 'jquery'; // Import jQuery
   import axios from 'axios';

   export default {
    data() {
    return {
      posts: [],
    };
  },
  methods:{
   testfn(){
    const SCROLL_SPEED = 0.3;
const CANVAS_WIDTH = 2800;

const bubblesEl = document.querySelector(".bubbles");
const bubbleSpecs = [
  { s: 0.6, x: 1134, y: 45 },
  { s: 0.6, x: 1620, y: 271 },
  { s: 0.6, x: 1761, y: 372 },
  { s: 0.6, x: 2499, y: 79 },
  { s: 0.6, x: 2704, y: 334 },
  { s: 0.6, x: 2271, y: 356 },
  { s: 0.6, x: 795, y: 226 },
  { s: 0.6, x: 276, y: 256 },
  { s: 0.6, x: 1210, y: 365 },
  { s: 0.6, x: 444, y: 193 },
  { s: 0.6, x: 2545, y: 387 },
  { s: 0.8, x: 1303, y: 193 },
  { s: 0.8, x: 907, y: 88 },
  { s: 0.8, x: 633, y: 320 },
  { s: 0.8, x: 323, y: 60 },
  { s: 0.8, x: 129, y: 357 },
  { s: 0.8, x: 1440, y: 342 },
  { s: 0.8, x: 1929, y: 293 },
  { s: 0.8, x: 2135, y: 198 },
  { s: 0.8, x: 2276, y: 82 },
  { s: 0.8, x: 2654, y: 182 },
  { s: 0.8, x: 2783, y: 60 },
  { x: 1519, y: 118 },
  { x: 1071, y: 233 },
  { x: 1773, y: 148 },
  { x: 2098, y: 385 },
  { x: 2423, y: 244 },
  { x: 901, y: 385 },
  { x: 624, y: 111 },
  { x: 75, y: 103 },
  { x: 413, y: 367 },
  { x: 2895, y: 271 },
  { x: 1990, y: 75 }
];

class Bubbles {
  constructor(specs) {
    this.bubbles = [];

    specs.forEach((spec, index) => {
      this.bubbles.push(new Bubble(index, spec));
    });

    requestAnimationFrame(this.update.bind(this));
  }

  update() {
    this.bubbles.forEach((bubble) => bubble.update());
    this.raf = requestAnimationFrame(this.update.bind(this));
  }
}

class Bubble {
  constructor(index, { x, y, s = 1 }) {
    this.index = index;
    this.x = x;
    this.y = y;
    this.scale = s;

    this.el = document.createElement("div");
    this.el.className = `bubble logo${this.index + 1}`;
    bubblesEl.appendChild(this.el);
  }

  update() {
    this.x = this.x < -200 ? CANVAS_WIDTH : this.x - SCROLL_SPEED;
    this.el.style.transform = `translate(${this.x}px, ${this.y}px) scale(${this.scale})`;
  }
}


this.bubbles =  new Bubbles(bubbleSpecs);

   }
  },
  mounted() {
    
    this.testfn();

    axios.get('https://wordpress-317567-994437.cloudwaysapps.com/wp-json/options/all')
      .then(response => {
        this.posts = response.data;
        this.our_partners_product_page = response.data.our_partners_product_page;
      });

    
    $(document).ready();




     console.log('test javascript');
  }
}


</script>


   <!-- Add "scoped" attribute to limit CSS to this component only -->
   <style lang="css">

.bubble-wrap {
  overflow: hidden;
  height: 600px;
}

.bubbles {
  position: relative;
  background: salmon;
}

.bubble {
  position: absolute;
  width: 152px;
  height: 152px;
  border-radius: 50%;
  box-shadow: 
    0 15px 35px rgba(0, 0, 0, 0.1),
    0 3px 10px rgba(0, 0, 0, 0.1);
  background-image: url(https://wordpress-317567-994437.cloudwaysapps.com/wp-content/uploads/2020/11/File-Partners-Logos-111120-1.png);
  background-size: 1076px 1076px;
}

.logo1 { background-position:   0      0; }
.logo2 { background-position:  -154px  0; }
.logo3 { background-position:  -308px  0; }
.logo4 { background-position:  -462px  0; }
.logo5 { background-position:  -616px  0; }
.logo6 { background-position:  -770px  0; }
.logo7 { background-position:  -924px  0; }
.logo8 { background-position:   0     -154px; }
.logo9 { background-position:  -154px -154px; }
.logo10 { background-position: -308px -154px; }
.logo11 { background-position: -462px -154px; }
.logo12 { background-position: -616px -154px; }
.logo13 { background-position: -770px -154px; }
.logo14 { background-position: -924px -154px; }
.logo15 { background-position:  0     -308px; }
.logo16 { background-position: -154px -308px; }
.logo17 { background-position: -308px -308px; }
.logo18 { background-position: -462px -308px; }
.logo19 { background-position: -616px -308px; }
.logo20 { background-position: -770px -308px; }
.logo21 { background-position: -924px -308px; }
.logo22 { background-position:  0     -462px; }
.logo23 { background-position: -154px -462px; }
.logo24 { background-position: -308px -462px; }
.logo25 { background-position: -462px -462px; }
.logo26 { background-position: -616px -462px; }
.logo27 { background-position: -770px -462px; }
.logo28 { background-position: -924px -462px; }
.logo29 { background-position:  0     -616px; }
.logo30 { background-position: -154px -616px; }
.logo31 { background-position: -308px -616px; }
.logo32 { background-position: -462px -616px; }
.logo33 { background-position: -616px -616px; }




   </style>
