<template>
 
    <!-- footer  --> 
   <div class="custom-footer">
      <div class="container">
         <div class="row">
            <div class="col-4">
                     <div class="footer-contact">
                     <h4>Contact</h4>
                     <p><strong>Address:</strong> 6705 NW 36th Street, Ste 490
                     Miami, FL 33166</p>
                     <p><strong>Phone:</strong> 305.468.3636</p>
                     <p><strong>Email:</strong> smile@thegemsgroup.com</p>
                     </div>
               </div>
               <div class="col-4">
                    <div class="footer-logo">
                        <img src="https://www.thegemsgroup.com/wp-content/uploads/2020/10/File-Gems-logo-white-100620.png" alt="">
                        <div class="social-media">
                           <ul>
                              <li><a href="#"><font-awesome-icon :icon="['fab', 'instagram']" /></a></li>
                                 <li><a href="#"><font-awesome-icon :icon="['fab', 'facebook-f']" /></a></li>
                                    <li><a href="#"><font-awesome-icon :icon="['fab', 'linkedin-in']" /></a></li>
                           </ul>
                        </div>
                        <p class="copyright">Copyright (C) 2024 The Gems Group</p>
                        <p class="footer-link">
                           <a href="/privacy-policy/">Private Policy</a>
                           <a href="/terms-and-conditions/">Terms &amp; Conditions </a>
                        </p>
                     </div>
               </div>
               <div class="col-4">
                  <div class="footer-message">
                     <div class="message">“People will forget what you said, people will forget what you did, but people will never forget how you made them feel.”</div>
                     <p>- Maya Angelou</p>
                  </div>
               </div>

         </div>
      </div>
   </div>
   <!-- end footer  -->

   </template>
   
   <!-- Add "scoped" attribute to limit CSS to this component only -->
   <style scoped>
   .custom-footer{
      background-position: right bottom 0px!important;
    background-image: url(https://www.thegemsgroup.com/wp-content/uploads/2020/06/File-Gems-Website-Footer-062920.png)!important;
    padding-top: 45% !important;
    padding-bottom: 0px!important;
    color: #fff;
    background-repeat: no-repeat;
    margin-top: -350px;
   }
   .custom-footer .footer-contact{
      font-size: 16px;
   }
   .custom-footer .footer-contact {
      margin-bottom: 0px;
   }
   .custom-footer .footer-contact h4{
      font-family: Avenir Bold;
      text-decoration: underline;
      font-size: 16px;
   }
   .custom-footer .footer-contact strong{
      font-family: Avenir Bold;
   }
   .custom-footer .footer-contact p{
      margin-bottom: 0px;
   }
   .custom-footer .footer-logo a{
      color:#fff;
      margin: 0px 10px;
   }
   .custom-footer .footer-logo .footer-link a{
      font-size: 12px;
      font-family: Avenir Bold;
   }
   .custom-footer .footer-logo{
      text-align: center;
   }
   .custom-footer .footer-logo img{
   max-width: 300px;
   }
   .custom-footer .social-media{
      padding:50px 0px;
   }
   .custom-footer .social-media ul{
      list-style: none;
    display: flex;
    text-align: center;
    padding-left: 0px;
    justify-content: center;
    align-items: center;
    margin: 0px;
   }
   .custom-footer .social-media ul li{
   padding:0px 5px
   }
   .custom-footer  .copyright{
   font-size: 14px;
   margin-bottom: 30px;
   }
   .custom-footer .footer-message{
      text-align: center;
   }
   .custom-footer .footer-message .message{
      font-size: 23px;
    font-weight: 700;
    font-family: Avenir Bold;
    margin-bottom: 10px;
   }
   </style>
   