<template>
  <div class="home">
    <div class="container">
          <div class="row">
            <div class="col-12 gap-bottom">
             <div v-html="secone_top_video"></div>
          </div>
        </div>
  </div>
    <div class="container">
          <div class="row">
            <div class="col-3">
              <MenuSidebar/>
          </div>
          <div class="col-9">
            <!--section 1-->
                <h2 class="content-one-title" v-html="sectwo_title"></h2>
                <div class="content-one-description gap-bottom" v-html="sectwo_description"></div>
              <!--end section 1-->
               <!--section 2-->
                  <h3 class="content-two-title" v-html="secthree_title"></h3>
                  <div class="content-two-description orange-border gap-bottom" v-html="secthree_description"></div>
             <!--end section 2-->
              <!--section 3-->
                  <h3 class="content-three-title" v-html="secfour_title"></h3>
                    <div class="container gap-bottom">
                            <!-- vloop-for  -->
                          <div class="row number-listing gap-bottom-small" v-for="loop in vloop" :key="loop.id">
                              <div class="col-2"><span class="number">{{ loop.number }}</span></div>
                              <div class="col-10">  <div v-html="loop.description"></div></div>
                          </div>
                    </div>
                  <!--end section 3-->
                  <!--section 4-->
                  <h2 class="content-four-title" v-html="secfour_title"></h2>
                  <OurPartnersProductPage/>
                  <!--end section 4-->
                  <!--section 5-->
                  <div class="container gap-bottom content-five">
                          <div class="row">
                              <div class="col-5">
                                <img :src="secfive_image">
                              </div>
                              <div class="col-7">
                                <h2 class="content-five-title" v-html="secfive_title"></h2>
                                <div class="content-five-description" v-html="secfive_description"></div>
                                <router-link to="/#" class="btn-orange">Smile Makers</router-link>
                              </div>
                          </div>
                    </div>
                  <!--end section 5-->
                   <!--section 6-->
                  <h2 class="content-six-title" v-html="secsix_title"></h2>
                  <img :src="secsix_image" class="gap-bottom">
                  <!--end section 6-->
                     <!--section 7-->
              <div class="content-seven sub-section">
                  <h2>This Just In!</h2>
                  <div class="blog-list">
                  <div class="container">
                    <div class="row">
                      <div class="col-4">
                           <img src="https://www.thegemsgroup.com/wp-content/uploads/2022/12/SMC_0170-300x300.jpg" alt="">
                           <h4 class="title">Extending Smiles: Reaching New Heights</h4>
                      </div>
                      <div class="col-4">
                           <img src="https://www.thegemsgroup.com/wp-content/uploads/2023/02/MicrosoftTeams-image-85-copy-300x300.jpg" alt="">
                           <h4 class="title">Camillus House for 1 Million Smiles</h4>
                      </div>
                      <div class="col-4">
                           <img src="https://www.thegemsgroup.com/wp-content/uploads/2022/12/MicrosoftTeams-image-82-300x300.jpg" alt="">
                           <h4 class="title">A Retreat Difficult to Beat</h4>
                      </div>
                    </div>
                    </div>
                  </div>
              </div>
              <!--end section 7-->
          </div>
        </div>
  </div>
</div>
</template>

<script>
   import MenuSidebar from '@/components/MenuSidebar.vue';
   import OurPartnersProductPage from '@/components/OurPartnersProductPage.vue';
   import axios from 'axios';

   export default {
    name: 'HomeView',
    components: {
        "MenuSidebar":MenuSidebar , 
        "OurPartnersProductPage":OurPartnersProductPage,
    },

    data() {
      return {
        posts: [],
        vloop:[]
      };
  },
  mounted() {
    axios.get('https://wordpress-317567-994437.cloudwaysapps.com/wp-json/wp/v2/pages/7069')
      .then(response => {
        this.posts = response.data;
        this.secone_top_video = response.data.acf.secone_top_video;
        this.sectwo_title = response.data.acf.sectwo_title;
        this.sectwo_description = response.data.acf.sectwo_description;
        this.secthree_title = response.data.acf.secthree_title;
        this.secthree_description = response.data.acf.secthree_description;
        this.secfour_title = response.data.acf.secfour_title;
        this.vloop = response.data.acf.secfour_description;
        this.secfive_title = response.data.acf.secfive_title;
       this.secfive_description = response.data.acf.secfive_description;
       this.secfive_image = response.data.acf.secfive_image.url;
       this.secsix_title = response.data.acf.secsix_title;
       this.secsix_image = response.data.acf.secsix_image.url;
      });


      
  }

    
}


</script>