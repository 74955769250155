<template>
 
 <div class="container">
    <div class="row">
      <div class="col-md-12">



 <!-- navbar  -->
 <div class="main-header">
    <div class="container">
        <div class="row">
          <div class="col-6">
            <button class="btn-menu" v-on:click="isHidden = !isHidden"><font-awesome-icon :icon="['fas', 'bars']" /> </button>
          </div>
          <div class="col-6 img-logo">
            <router-link to="/"><img src="https://www.thegemsgroup.com/wp-content/uploads/2021/09/File-Gems-logo-color-100620.png" alt=""></router-link>
          </div>
        </div>
    </div>
  </div>  
<div class="main-menu" v-if="isHidden">
  <button class="btn-close" v-on:click="isHidden = false">X</button>
  <ul>
    <li><router-link to="/">our story</router-link></li>
    <li><router-link to="/smile-makers">g.e.m.s.</router-link></li>
    <li><a href="#">1 million smiles</a></li>
    <li><a href="#">the group</a></li>
      <li><a href="#">in the news</a></li>
     
  </ul>
  
</div>
<!-- end navbar  -->




</div>
    </div>
  </div>

</template>

<script>
import $ from 'jquery'; // Import jQuery
export default {
  data () {
   return {
     toggle: false,
     isHidden: false
   }
 },

  
mounted(){
 
$('.main-menu').hide();
$('.main-menu').css('background-color', 'orange');
$('#menu').click(function(){
  $('.main-menu').show();
});

}

}




</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
.img-logo img{
    max-width: 187px;
}
.img-logo{
    text-align:right;
}
.main-header{
    padding:20px 0px;
}
.btn-menu{
border: 0px;
    background: transparent;
    font-size: 24px;
    margin-top: 20px;
}
.main-menu{
  background: #FF5100;
    position: fixed;
    z-index: 999;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    opacity: .95;
}
.btn-close{
  border: 0px;
    background: transparent;
    margin-top: 20px;
    float: right;
    color: #fff;
    font-size: 38px;
}
</style>
