import { createApp } from 'vue';
import App from './App.vue';
import router from './router';


//bootstrap
import 'bootstrap'; 
import 'bootstrap/dist/css/bootstrap.min.css';

//main css
import "./assets/css/style.css"

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* import specific icons */
//import { all } from '@awesome.me/kit-KIT_CODE'
import { faBars } from '@fortawesome/free-solid-svg-icons'

import { faLinkedinIn,faInstagram,faFacebookF} from '@fortawesome/free-brands-svg-icons'

/* add icons to the library */
library.add(faBars,faLinkedinIn,faInstagram,faFacebookF)

//App.component('font-awesome-icon', FontAwesomeIcon)
createApp(App)
.use(router)
.component('font-awesome-icon', FontAwesomeIcon)
.mount('#app')
