<template>
 
    <!-- navbar  --> 
   <div class="sidebar-menu">
     <ul>
      <li><router-link to="/">our story</router-link></li>
       <li><router-link to="/smile-makers">g.e.m.s.</router-link></li>
       <li><router-link to="/1-million-smiles">1 million smiles</router-link></li>
       <li><a href="#">the group</a></li>
         <li><a href="#">in the news</a></li>
     </ul>
   </div>
  
   <!-- end navbar  -->
   
   

   
   </template>
   
   
   <!-- Add "scoped" attribute to limit CSS to this component only -->
   <style scoped>
   .sidebar-menu ul{
    list-style: none;
    padding-left: 0px;
   }

   .sidebar-menu ul li a{
      font-weight: 700;
      font-size: 22px;
      color: #98989A;
      text-decoration: none;
      font-family: Avenir Bold;
  }
  .sidebar-menu ul li{
  margin-bottom: 5px;
  }
   </style>
   